define("rtv-de-bollenstreek/index", ["exports", "routed-enhancement", "./component/gpx-route"], function (_exports, _routedEnhancement, _gpxRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _routedEnhancement = _interopRequireDefault(_routedEnhancement);
  _gpxRoute = _interopRequireDefault(_gpxRoute);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

  function _default(options) {
    var router = new _routedEnhancement.default(options.name + '/controller'); // defaults

    router.ready('polyfill');
    router.ready('login');
    router.component('input[type=password]', 'password-strength');
    router.selector('object', 'object-full-screen');
    router.selector('a', 'external-links'); // navigation

    router.selector('.c-nav', 'nav/main');
    router.selector('.c-nav', 'nav/more');
    router.selector('.c-nav-wrapper', 'nav/move');
    router.selector('.c-subnav', 'nav/subnav'); // platform

    router.selector('#profile-photo', 'avatar-crop');
    router.selector('ul.almanac li', 'almanac');
    router.selector('a[rel^=lightbox]', 'lightbox');
    router.selector('.avatar', 'avatar-fallback');
    router.selector('.c-message-feed', 'message-feed');
    router.selector('table', 'responsive-tables');
    router.selector('.js-image-slider', 'image-slider');
    router.selector('.js-fullcalendar', 'fullcalendar');
    router.selector('.js-login-modal', 'login-move');
    router.selector('.js-banner-carousel', 'banners'); // event app

    router.selector('.shop-cart__form', 'event-app/cart-form');
    router.selector('.calendar__data', 'event-app/calendar');
    router.selector('.participants__title', 'event-app/participants');
    router.selector('.event-program__collapser', 'event-app/program');
    router.selector('.preference-form', 'event-app/preferences-sort');
    router.selector('.event-speaker__image img', 'avatar-fallback'); // career

    router.selector('.career-search-item', 'career/item-search');
    router.selector('.career-search-company', 'career/company-search'); // webshop

    router.selector('.webshop-fullproduct__gallery', 'webshop/webshop-thumbs');
    router.selector('.webshop-checkout', 'webshop/webshop-cart');
    router.dispatch();
    customElements.define('gpx-route', _gpxRoute.default);
  }
});