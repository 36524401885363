(function(define, module){
( function( factory ) {
	if ( typeof define === "function" && define.amd ) {

		// AMD. Register as an anonymous module.
		define( [ "jquery" ], factory );
	} else {

		// Browser globals
		factory( jQuery );
	}
} ( function( $ ) {

$.ui = $.ui || {};

return $.ui.version = "1.12.1";

} ) );

if (define.defined === false && module.exports) { define(function () { return module.exports; }); }
})((function(){ function newDefine(){ var args = Array.prototype.slice.call(arguments);while (typeof args[0] === "string") { args.shift(); }args.unshift("jquery-ui/version"); if (typeof args[args.length - 1] !== "function") { var returnCallbackValue = args[args.length - 1];args[args.length - 1] = function () { return returnCallbackValue; }}define.defined = true; var result = define.apply(null, args);return result;};newDefine.amd = true; newDefine.defined = false; return newDefine; })(), {});